// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "chartkick/chart.js"

import "./jquery.formobserver.js"

import moment from 'moment/moment'
window.moment = moment
import 'moment/locale/de-ch'

window.Calendar = require("@fullcalendar/core").Calendar;
window.dayGridPlugin = require("@fullcalendar/daygrid").default;
window.listPlugin = require("@fullcalendar/list").default;
window.esLocale = require('@fullcalendar/core/locales/es').default;


Rails.start()
Turbolinks.start()
ActiveStorage.start()

